import {
  PARTICAPNT_INFO,
  SHAPE_FILE_UPLOAD_CONTENT,
  convertStatus,
  fieldAddTypes,
  mapControlOptions,
  toastStyle,
  enrollmentInstanceStatus,
  errorPolygonOptions,
  getFieldAreaExceedingError,
  LEFT_MENU_SECTION_HEADERS,
  getAddFarmRequestBody,
  UNSAVED_CHANGES_MODAL_PROPS_INIT,
  PAGE_CONTENT as DATA_COLLECTION_CONTENT,
  handleNextInProgrssSwitcher,
  ENROLLMENT_SUBMITTED_MESSAGE,
} from 'pages/ParticipantDataCollection/ParticipantDataCollection.content';
import PropTypes from 'prop-types';
import ParticipantGeneralFarmInfo from 'pages/ParticipantDataCollection/ParticipantGeneralFarmInfo/ParticipantGeneralFarmInfo.react';
import LeftMenuSubsection from 'pages/ParticipantDataCollection/components/LeftMenuSubsection/LeftMenuSubsection.react';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import ParticipantGenralInfoForm from 'pages/ParticipantDataCollection/components/ParticipantGeneralInfoForm/ParticipantGeneralInfoForm.react';
import { useLocation, useNavigate } from 'react-router';
import MapSearchContainer from 'components/MapComponent/MapSearchContainer.react';
import { uniqueId } from 'utils/uniqueIdGenerator';
import {
  checkTernaryCondition,
  getTopMostCoordinate,
  parseBoundaries,
  formatBoundaries,
  formatGeoJson,
  isPointInsidePolygon,
  doLinesIntersect,
  convertSquareMetersToAcres,
  getNextName,
  convertDateFormat,
  isEmpty,
  getFarmLockedTooltip,
} from 'utils/helper';
import ParticipantGeneralFieldInfoForm from 'pages/ParticipantDataCollection/components/ParticipantGeneralFieldInfoForm/ParticipantGeneralFieldInfoForm.react';
import {
  BOUNDARY_SHAPE_FILE_UPLOAD,
  FETCH_COUNTY_LIST,
  FETCH_FARM_INFO_LIST,
  FETCH_GENERAL_FARM_INFO,
  FETCH_STATE_LIST,
  FETCH_VIEW_ORIGINATION_PROJECT,
  PARTICIPANT_PROFILE,
  PARTICIPANT_REPORT_FIRST_PART,
  FETCH_PROJECT_CYCLES,
  ADD_FARM,
  VALIDATE_FIELD_SPATIAL_DATA,
  UPDATE_FARM_STATUS,
  FARM_HEART_BEAT,
  FETCH_USER_LOCK_DETAILS,
  FETCH_FARM_LOCKOUT_TIME,
} from 'urls';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import { Backdrop, CircularProgress, Divider } from '@mui/material';
import { PAGE_CONTENT } from 'pages/ParticipantDataCollection/components/ParticipantGeneralInfoForm/ParticipantGeneralInfoForm.content';
import { pathName } from 'Routes/routes.const';
import { toast } from 'react-toastify';
import CustomSnackbar from 'components/CustomSnackbar/CustomSnackbar.react';
import {
  backdropSx,
  fieldMapViewStyle,
  mapStyle,
} from 'pages/ParticipantDataCollection/ParticipantDataCollection.style';
import FarmWithMapTabs from 'pages/ParticipantDataCollection/components/FarmWithMapTabs/FarmWithMapTabs.react';
import FieldAddTypeUpdaters from 'pages/ParticipantDataCollection/components/FieldAddTypeUpdaters/FieldAddTypeUpdaters.react';
import AddFieldsModal from 'pages/ParticipantDataCollection/components/LeftMenuFieldData/AddFieldsModal/AddFieldsModal.react';
import MultiFilesUploader from 'components/FileUploader/MultiFilesUploader.react';
import { getApiError } from 'components/FileUploader/FileUploader.content';
import InfoWindow from '../pages/ParticipantDataCollection/components/InfoWindows/InfoWindow.react';
import LoadingInfoWindow from 'pages/ParticipantDataCollection/components/InfoWindows/LoadingWindow.react';
import { usRegionsPolygons } from 'pages/Origination/OriginationSummaryTab/OriginationSummaryTab.content';
import ProfileInfoWithTabber from 'pages/ParticipantDataCollection/components/ProfileInfoWithTabber/ProfileInfoWithTabber.react';
import FieldInfoWithTabber from 'pages/ParticipantDataCollection/components/FieldInfoWithTabber/FieldInfoWithTabber.react';
import {
  ACTIVITY_TYPES,
  ADMIN,
  ERROR_MSGS,
  FARM_LOCK_MODALS_DATA,
  GLOBAL_STRING_CONSTANTS,
  ORIGINATION_MANAGER,
  PARTICIPANT,
  PARTICIPANT_CONTRIBUTOR,
} from 'utils/config';
import { userAuthRolesContext } from './userAuthRolesContext';
import { LANDING_PAGE_CONTENT } from 'pages/ParticipantDataCollection/pages/ParticipantPages.content';
import useThrottle from 'hooks/useThrottle';
import {
  fetchAllUserActions,
  getFarmsWithSortedFields,
  isActivitySubmitted,
} from 'pages/ParticipantDataCollection/ReportingUtilFunctions';
import { displayToast } from 'pages/OriginationProjectList/OriginationProjectList.content';

import {
  ButtonContainer,
  MapViewContainerWrapper,
  NavigationBtnWrapper,
} from 'pages/ParticipantDataCollection/components/ParticipantGeneralInfoForm/ParticipantGeneralInfoForm.style';
import UserNavigationButtonsV2 from 'pages/ParticipantDataCollection/components/UserNavigationButtonsV2/UserNavigationButtonsV2.react';
import { useInterval } from 'hooks/useInterval';
import { useIdleTimer } from 'react-idle-timer';
import FarmLockModal from 'components/FarmLockModal/FarmLockModal';
import { TimerContent } from 'pages/ParticipantDataCollection/components/ApplicationSession/TimeoutComponent.react';
import DialogBox from 'components/DialogBox/DialogBox.react';
import { DARK_CHARCOAL } from 'theme/GlobalColors';
import {
  FarmExtendSx,
  FarmLockingSx,
  FarmLockModalSubtitleSx,
} from 'components/FarmLockModal/FarmLockModal.style';
const SUCCESS = 'Success';

// To create participant info context
export const participantInfoContext = createContext({});
export const ParticipantInfoProvider = ({ children }) => {
  // Note: Value of this state is 1 for profile, 2 for farm/field, 3 for "Add farms' page
  const [rightSectionId, setRightSectionId] = useState(null);
  const [participantInfo, setParticipantInfo] = useState(PARTICAPNT_INFO);
  const [farmInfo, setFarmInfo] = useState([]);
  const [farmListStatus, setFarmListStatus] = useState('');
  const [farmListIds, setFarmListIds] = useState([]);
  const [statusList, setStatusList] = useState('');
  const [currentFarmId, setCurrentFarmId] = useState(-1);
  const [farmLoading, setFarmLoading] = useState(false);
  const [fetchedFarmIds, setFetchedFarmIds] = useState(false);
  const [currentFarmInfo, setCurrentFarmInfo] = useState({});
  const [expandedFarmId, setExpandedFarmId] = useState(-1);
  const [openMapView, setOpenMapView] = useState(false);
  const [showFieldInfoForm, setShowFieldInfoForm] = useState(false);
  const [fetchedFarmValues, setFetchedFarmValues] = useState([]);
  const [isEditModeOn, setIsEditModeOn] = useState(false);
  const [savedFieldForms, setSavedFieldForms] = useState([]);
  const { state } = useLocation();
  const [sectionList, setSectionList] = useState([]);
  const [selectedFieldId, setSelectedFieldId] = useState(null);
  const [saveAndCloseClicked, setSaveAndCloseClicked] = useState(false);
  const [searchParams] = useSearchParams();
  const [fieldPolygons, setFieldPolygons] = useState([]);
  const [fieldMarkers] = useState([]);
  const [fieldInfoWindows, setFieldInfoWindows] = useState([]);
  const [fieldAddTypeModalOpen, setFieldAddTypeModalOpen] = useState(false);
  const [selectedFieldAddType, setSelectedFieldAddType] = useState(null);
  const [farmInfoCurrentTab, setFarmInfoCurrentTab] = useState(0);
  const [farmIdsWithExistingFields, setFarmIdsWithExistingFields] = useState(
    [],
  );
  const [participantProfileStatus, setParticipantProfileStatus] =
    useState(null);
  const [modalData, setModalData] = useState({
    flag: false,
    id: 0,
    isDataSubmitted: false,
  });
  const [loading, setLoading] = useState(false);
  const [loaderCounter, setLoaderCounter] = useState(0);
  const [filesUploadError, setFilesUploadError] = useState({
    flag: false,
    message: [],
  });
  const [shapeFileUploadModalOpen, setShapeFileUploadModalOpen] =
    useState(false);
  const [mapCenter, setMapCenter] = useState(null);
  const [fieldIdsAddedFromMap, setFieldIdsAddedFromMap] = useState([]);
  const projectId = searchParams.get('projectid');
  const participantId = searchParams.get('participantid');
  const enrollmentInstanceId = searchParams.get('enrollmentInstanceId');
  const projectCycleId = searchParams.get('projectcycleid');
  const navigate = useNavigate();
  const [addFarmClicked, setAddFarmClicked] = useState({ flag: false, id: 0 });
  const [isTabSwitched, setIsTabSwitched] = useState(true);
  const [fieldSelectionInProgress, setFieldSelectionInProgress] =
    useState(false);
  const [tempBoundaries, setTempBoundaries] = useState(null);
  const [mapBounds, setMapBounds] = useState(null);
  const [projectRegions, setProjectRegions] = useState([]);
  const [isFormUnmounted, setIsFormUnmounted] = useState({
    currentPage: null,
    flag: false,
    id: 0,
  });
  const [submitData, setSubmitData] = useState({
    currentPage: null,
    data: null,
    currentId: null,
  });
  // Used to check if user has made any changes to the web forms(profile, farm or field), based on this we prompt user when they try to leave the page with unsaved changes
  const [userActivityDetected, setUserActivityDetected] = useState(false);
  const [profileInfoTab, setProfileInfoTab] = useState(0);
  const [fieldInfoTab, setFieldInfoTab] = useState(0);
  const [isFieldDeleted, setIsFieldDeleted] = useState(false);
  // Note: Below 3 states are being used to determine if we have to enable/disable the web forms based on the enrollment instances, previous instance data will just be visible and disabled, only current instance data will be editable.
  const [isProfileDataEditable, setIsProfileDataEditable] = useState(true);
  const [enrolledFarmIds, setEnrolledFarmIds] = useState([]);
  const [enrolledFieldIds, setEnrolledFieldIds] = useState([]);
  // Note: This flag indicates if there is atleast one farm or field in current enrollment instance. This will be used to check if we want to show enrollment complete modal or not. We will show the modal only if atleast one farm or field is present in current enrollment instance.
  const [hasNotApprovedFarmOrFields, setHasNotApprovedFarmOrFields] =
    useState(false);
  const [isFarmCsafUpdatedBySystem, setIsFarmCsafUpdatedBySystem] =
    useState(false);
  const [isFieldSubmitted, setIsFieldSubmitted] = useState(false);
  const currentFarmIndex = farmInfo.findIndex(
    (farm) => farm.id === currentFarmId,
  );
  // Note: This will have the sum of areas of all fields under current farm
  const [currentFarmFieldsArea, setCurrentFarmFieldsArea] = useState(0);
  const [currentCycleDataYear, setCurrentCycleDataYear] = useState('');
  const [currentCycleStartDate, setCurrentCycleStartDate] = useState('');
  const [currentCycleEndDate, setCurrentCycleEndDate] = useState('');
  const [triggerFieldInfoFetch, setTriggerFieldInfoFetch] = useState(false);
  const [unsavedChangesModalOpenProps, setUnsavedChangesModalOpenProps] =
    useState(UNSAVED_CHANGES_MODAL_PROPS_INIT);
  const [triggerFarmInfoSubmit, setTriggerFarmInfoSubmit] = useState(false);
  const [triggerFieldInfoSubmit, setTriggerFieldInfoSubmit] = useState(false);
  const [triggerProfileInfoSubmit, setTriggerProfileInfoSubmit] =
    useState(false);
  const [addedFromUiFarmIds, setAddedFromUiFarmIds] = useState([]);
  const {
    userRoles,
    userDetails: { userId },
  } = useContext(userAuthRolesContext);
  const isParticipantContributor = userRoles?.includes(PARTICIPANT_CONTRIBUTOR);
  const isParticipant = userRoles?.includes(PARTICIPANT);
  const resetFieldViewTab = () => setFieldInfoTab(0);
  const closeUnsavedChangesModal = () =>
    setUnsavedChangesModalOpenProps(UNSAVED_CHANGES_MODAL_PROPS_INIT);
  const [statusMappingList, setStatusMappingList] = useState([]);
  const [switchState, setSwitchState] = useState(false);
  const [isSwitchDisabled, setIsSwitchDisabled] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [projectIdValue, setProjectIdValue] = useState(null);
  const [participantIdValue, setParticipantIdValue] = useState(null);
  const [activeUser, setActiveUser] = useState({
    activeUserId: null,
    name: null,
  });

  const [isInactiveTimerModalOpen, setIsInactiveTimerModalOpen] =
    useState(false);
  const [isInactiveModalOpen, setIsInactiveModalOpen] = useState(false);
  /**
   * Boolean state variable to maintain the disabled state of SubmitAllFarmsButton globally.
   *
   * @type {boolean}
   */
  const [disableSubmitAllFarmsBtn, setDisableSubmitAllFarmsBtn] = useState();

  const [idleTimeout, setIdleTimeout] = useState();
  const [showBlockedModal, setShowBlockedModal] = useState(false);

  /**
   * Boolean state variable to maintain check if the enrollment is completed all together or not.
   * This indicates whether all the profile form and field form have the state of completed.
   *
   * @type {[boolean, Function]}
   */
  const [
    isEnrollmentCompletedAllTogether,
    setIsEnrollmentCompletedAllTogether,
  ] = useState(true);

  /**
   * @description function to update inactive modal state stating the farm will be unlocked after timer ends
   */
  const handleTimerOnPrompt = () => {
    setIsInactiveTimerModalOpen(true);
  };

  /**
   * @description function to handle user inactivity for timeout duration
   */
  /* istanbul ignore next */
  const onUserIdle = () => {
    resetTimer();
    setIsInactiveTimerModalOpen(false);
    handleSwitchChange({ target: { checked: false } });
    setIsInactiveModalOpen(true);
    pause();
  };

  /**
   * @description function to trigger farm hearbeat when switched enabled
   */
  const updateFarmHeartBeat = () => {
    /* istanbul ignore else */
    if (switchState) {
      /* istanbul ignore next */
      axios
        .put(FARM_HEART_BEAT, {
          farmId: currentFarmId,
          projectId: projectIdValue,
          lockStatus: switchState,
          participantId: participantId,
        })
        .catch(() => {
          /* do nothing here */
        });

      const timeDifference =
        Math.abs(new Date().getTime() - getLastActiveTime()?.getTime()) / 60000;
      /* istanbul ignore else */
      if (timeDifference > (+idleTimeout || 15)) {
        onUserIdle();
      }
    }
  };

  // triggering farm heartbeat every 10 seconds
  useInterval(updateFarmHeartBeat, 10 * 1000);

  const promptBeforeIdle = 1000 * 60 * 2; //2 minute

  const {
    reset: resetTimer,
    pause,
    start,
    getLastActiveTime,
  } = useIdleTimer({
    timeout: 1000 * 60 * (+idleTimeout || 15),
    promptBeforeIdle: promptBeforeIdle,
    onPrompt: handleTimerOnPrompt,
    throttle: 500,
    onIdle: onUserIdle,
    startOnMount: false,
    startManually: true,
  });

  const onExtendSession = () => {
    setIsInactiveTimerModalOpen(false);
    setIsInactiveModalOpen(false);
    getSwitchState();
    handleSwitchChange({ target: { checked: true } }, true);
  };

  const enrollmentDataCollectionCompleted = useMemo(() => {
    const isProfileDataCompleted =
      participantProfileStatus === DATA_COLLECTION_CONTENT.completed;
    const isFarmDataCompleted =
      farmInfo.length > 0 &&
      farmInfo.every((farm) => {
        const isFarmCompleted =
          farm.status === DATA_COLLECTION_CONTENT.completed ||
          farm.status === DATA_COLLECTION_CONTENT.complete;
        const isFieldsCompleted = farm.fields.every(
          (field) => field.status === DATA_COLLECTION_CONTENT.completed,
        );
        return isFarmCompleted && isFieldsCompleted;
      });

    return isProfileDataCompleted && isFarmDataCompleted;
  }, [participantProfileStatus, farmInfo]);

  const hasUnApprovedFarmsOrFields = useMemo(() => {
    return (
      farmInfo.length > 0 &&
      farmInfo.some((farm) => {
        const isFarmUnApproved =
          farm.enrollmentStatus !== enrollmentInstanceStatus.approved;
        const hasUnApprovedFields = farm.fields.some((field) => {
          return field.enrollmentStatus !== enrollmentInstanceStatus.approved;
        });
        return isFarmUnApproved || hasUnApprovedFields;
      })
    );
  }, [farmInfo]);

  const handleLeaveWithoutSave = () => {
    switch (unsavedChangesModalOpenProps.navigateNextTo) {
      case LEFT_MENU_SECTION_HEADERS.farmAndFieldsSection:
        goToFarm(unsavedChangesModalOpenProps?.targetFarmId);
        break;
      case LEFT_MENU_SECTION_HEADERS.profileSection: {
        goToProfile();
        break;
      }
      /* istanbul ignore next */
      case PAGE_CONTENT.field:
        openFieldInformation({
          id: unsavedChangesModalOpenProps?.targetFieldId,
          name: unsavedChangesModalOpenProps?.targetFieldName,
          farmId: unsavedChangesModalOpenProps?.targetFarmId,
        });
        break;
      /* istanbul ignore next */
      case DATA_COLLECTION_CONTENT.addFarms:
        addFarmClickHandler();
        break;
      /* istanbul ignore next */
      case DATA_COLLECTION_CONTENT.ADD_FIELD:
        setUserActivityDetected(false);
        openGoogleMaps(unsavedChangesModalOpenProps?.targetFarmId);
        break;
    }
    closeUnsavedChangesModal();
    fetchParticipantData(false);
  };

  const handleSaveAndLeave = () => {
    const currentPage = getCurrentPage();

    switch (currentPage) {
      case 'Farm':
        setTriggerFarmInfoSubmit(true);
        break;
      case 'Field':
        setTriggerFieldInfoSubmit(true);
        break;
      case 'Profile':
        setTriggerProfileInfoSubmit(true);
        break;
    }
  };

  const handleSubmitCallback = () => {
    switch (unsavedChangesModalOpenProps.navigateNextTo) {
      case PAGE_CONTENT.field:
        openFieldInformation({
          id: unsavedChangesModalOpenProps.targetFieldId,
          name: unsavedChangesModalOpenProps.targetFieldName,
          farmId: unsavedChangesModalOpenProps.targetFarmId,
        });
        break;
      case PAGE_CONTENT.farms:
        {
          goToFarm(unsavedChangesModalOpenProps.targetFarmId);
        }
        break;
      case PAGE_CONTENT.participant:
        {
          goToProfile();
        }
        break;
      /* istanbul ignore next */
      case DATA_COLLECTION_CONTENT.addFarms:
        {
          addFarmClickHandler();
        }
        break;
      case DATA_COLLECTION_CONTENT.ADD_FIELD:
        setUserActivityDetected(false);
        openGoogleMaps(unsavedChangesModalOpenProps?.targetFarmId);
        break;
    }
    closeUnsavedChangesModal();
  };

  const fetchCurrentCycleData = () => {
    setLoaderCounter((prev) => prev + 1);
    const URL = `${FETCH_PROJECT_CYCLES}/${projectId}`;
    axios
      .get(URL)
      .then((response) => {
        const data = response.data;
        /* istanbul ignore else */
        if (data && data.length > 0) {
          const currentCycleData = data.find(
            (cycle) => cycle.projectCycleId == projectCycleId,
          );

          //format the date for the cycle start
          let currentCycleStartFormatted = convertDateFormat(
            currentCycleData?.cycleStartDate,
          );
          setCurrentCycleStartDate(currentCycleStartFormatted);
          //format the date for the cycle end
          let currentCycleEndFormatted = convertDateFormat(
            currentCycleData?.cycleEndDate,
          );

          setCurrentCycleEndDate(currentCycleEndFormatted);
          let currentCycleYear = new Date(currentCycleData?.cycleEndDate)
            ?.getFullYear()
            ?.toString();
          setCurrentCycleDataYear(currentCycleYear);
        }
      })
      .catch(() => {
        /* istanbul ignore else */
        displayToast('error', ERROR_MSGS.FETCH);
      })
      .finally(() => setLoaderCounter((prev) => prev - 1));
  };

  const fetchFarmList = async () => {
    setLoaderCounter((prev) => prev + 1);
    return axios
      .get(FETCH_FARM_INFO_LIST, {
        params: { participantId: participantId, projectId: projectId },
      })
      .then((response) => {
        const farms = response.data;
        return farms;
      })
      .catch(() => {
        /* istanbul ignore else */
        displayToast('error', ERROR_MSGS.FETCH);
      })
      .finally(() => setLoaderCounter((prev) => prev - 1));
  };

  useEffect(() => {
    if (+currentFarmId <= 0 || +expandedFarmId <= 0) {
      pause();
      setIsInactiveTimerModalOpen(false);
      setIsInactiveModalOpen(false);
    }
    if (switchState) {
      resetTimer();
      start();
    } else {
      setIsInactiveTimerModalOpen(false);
      pause();
      // removing the unaccepted polygon when switch is turned off
      fieldPolygons.forEach(({ fieldId }) => {
        /* istanbul ignore else */
        if (typeof fieldId === 'string') {
          removeFieldPolygon(fieldId);
        }
      });
      setFieldAddTypeModalOpen(false);
      setShapeFileUploadModalOpen(false);
      closeUnsavedChangesModal();
    }
  }, [switchState]);

  /* istanbul ignore next */
  const isNewPolygonEdgesIntersectingWithOthers = (newPolygonBoundaries) => {
    const length = newPolygonBoundaries.length;

    // For each edge of the new polygon
    for (let start = 0, end = length - 1; start <= length - 1; end = start++) {
      const currentLineCoordinates = {
        lat1: newPolygonBoundaries[start].lat,
        lng1: newPolygonBoundaries[start].lng,
        lat2: newPolygonBoundaries[end].lat,
        lng2: newPolygonBoundaries[end].lng,
      };

      // For each existing field
      for (const field of fieldPolygons) {
        // Note: We are skipping fields from other farms and also skipping checks with the same field
        // The check for fieldId not to be string is added since during edit polygon, we will already have a polygon in the fieldPolygons state, only way to skip checking the same field for overlapping is to check if the id is a string(uniqueId generated for newly added fields before api calls)
        if (field.farmId !== currentFarmId || typeof field.fieldId === 'string')
          continue;

        const boundaries = field.fieldBoundaries;
        const boundariesLength = boundaries.length;
        // Check for intersection of all edges of this field
        for (
          let front = 0, back = boundariesLength - 1;
          front <= boundariesLength - 1;
          back = front++
        ) {
          const existingLineCoordinates = {
            lat1: boundaries[front].lat,
            lng1: boundaries[front].lng,
            lat2: boundaries[back].lat,
            lng2: boundaries[back].lng,
          };

          if (doLinesIntersect(currentLineCoordinates, existingLineCoordinates))
            return true;
        }
      }
    }

    return false;
  };

  /* istanbul ignore next */
  const isNewPolygonPointsInsideExistingPolygons = (newPolygonBoundaries) => {
    for (const polygon of fieldPolygons) {
      // Note: We are skipping fields from other farms and also skipping checks with the same field
      // The check for fieldId not to be string is added since during edit polygon, we will already have a polygon in the fieldPolygons state, only way to skip checking the same field for overlapping is to check if the id is a string(uniqueId generated for newly added fields before api calls)
      if (
        polygon.farmId !== currentFarmId ||
        typeof polygon.fieldId === 'string'
      )
        continue;

      for (const point of newPolygonBoundaries) {
        if (isPointInsidePolygon(polygon.fieldBoundaries, point)) return true;
      }
    }

    return false;
  };

  /* istanbul ignore next */
  const isExistingPolygonsPointsInsideNewPolygon = (newPolygonBoundaries) => {
    for (const polygon of fieldPolygons) {
      // Note: We are skipping fields from other farms and also skipping checks with the same field
      // The check for fieldId not to be string is added since during edit polygon, we will already have a polygon in the fieldPolygons state, only way to skip checking the same field for overlapping is to check if the id is a string(uniqueId generated for newly added fields before api calls)
      if (
        polygon.farmId !== currentFarmId ||
        typeof polygon.fieldId === 'string'
      )
        continue;

      const polygonBoundaries = polygon.fieldBoundaries;
      for (const point of polygonBoundaries) {
        if (isPointInsidePolygon(newPolygonBoundaries, point)) return true;
      }
    }

    return false;
  };

  /* istanbul ignore next */
  const isPolygonOverlapping = (newPolygonBoundaries) => {
    setLoaderCounter((prev) => prev + 1);
    const overlapping =
      isNewPolygonPointsInsideExistingPolygons(newPolygonBoundaries) ||
      isExistingPolygonsPointsInsideNewPolygon(newPolygonBoundaries) ||
      isNewPolygonEdgesIntersectingWithOthers(newPolygonBoundaries);
    setLoaderCounter((prev) => prev - 1);
    return overlapping;
  };

  // This function returns the total sum of all the fields acres in the selected farm
  function getCurrentFarmFieldsArea() {
    const currentFarm = farmInfo.find((farm) => farm.id === currentFarmId);
    if (!currentFarm) return 0;

    return (
      currentFarm.fields?.reduce(
        (total, field) => total + Number(field.totalArea),
        0,
      ) ?? 0
    );
  }

  const addFieldToFarm = (fieldId, fieldName, farmIndex, fieldBoundaries) => {
    if (farmIndex < 0 || farmIndex >= farmInfo.length) return;
    const url = `${PARTICIPANT_REPORT_FIRST_PART}/enroll/general-field-info?farmId=${currentFarmId}&participantId=${participantId}&projectId=${projectId}`;
    const fieldArea = +convertSquareMetersToAcres(
      getPolygonArea(fieldBoundaries),
    );
    const farmArea = Number(currentFarmInfo.totalFarmAcreage);
    const emptyData = {
      enrollmentInstanceId: Number(enrollmentInstanceId),
      projectCycleId: Number(projectCycleId),
      fieldName: fieldName,
      formStatus: 'In Progress',
      fsaFieldId: '',
      fsaTractId: '',
      /*       csafImplemented: '', */
      activityList: [],
      totalFieldArea: fieldArea,
      /*   csafUpdatedBySystem: false,
      farmCsafUpdatedByField: false, 
      isConsistentLanduse20Yrs: '',
      landUseTypeId: '',*/
      fieldHistoricLanduseList: [],
    };

    setLoaderCounter((prev) => prev + 1);
    const formattedBoundaries = formatBoundaries(fieldBoundaries);
    const reqBody = [...formattedBoundaries, formattedBoundaries[0]];

    const handleSuccess = (resp) => {
      if (!resp.data) return;

      updateFarmInformation(farmIndex, resp.data);
      updateFieldPolygons(fieldId, fieldName, resp.data.fieldId);
      updateFieldInfoWindows(fieldId);
      showSuccessToast(resp.data.fieldName);
      checkFieldAreaExceeding(farmArea, fieldArea);
      setHasNotApprovedFarmOrFields(true);
      fetchParticipantData(false);
    };

    const handleError = () => {
      showErrorToast(fieldName);
    };

    axios
      .post(VALIDATE_FIELD_SPATIAL_DATA, reqBody)
      .then(({ data }) => {
        if (data.status === SUCCESS && !data.message) {
          const spatialPolygonDataId = data?.data?.spatialPolygonDataId;
          return axios
            .post(url, { ...emptyData, spatialPolygonDataId })
            .then(handleSuccess)
            .catch((error) => {
              const errorMessage = error?.response?.data?.errorCodeMsg;
              if (errorMessage === ENROLLMENT_SUBMITTED_MESSAGE)
                NavigateOutOfEnrollment();
              else handleError();
            });
        } else {
          toast(
            <CustomSnackbar type="error" message={data.message} />,
            toastStyle,
          );
        }
      })
      .catch(() => {
        /* istanbul ignore else */
        displayToast('error', ERROR_MSGS.RETRIEVE);
      })
      .finally(() => {
        setLoaderCounter((prev) => prev - 1);
        setFieldSelectionInProgress(false);
      });
  };

  const updateFarmInformation = (farmIndex, data) => {
    setFarmInfo((prevInfo) =>
      prevInfo.map((farm, index) =>
        checkTernaryCondition(
          index === farmIndex,
          {
            ...farm,
            fields: [
              ...farm.fields,
              {
                id: data.fieldId,
                value: data.fieldName,
                status: data.formStatus,
                info: data.fieldName,
                isApiPopulated: true,
              },
            ],
          },
          farm,
        ),
      ),
    );
    setFieldIdsAddedFromMap((prev) => [...prev, data.fieldId]);
    setFarmIdsWithExistingFields((prev) => [...prev, farmInfo[farmIndex].id]);
  };

  const updateFieldPolygons = (fieldId, fieldName, newFieldId) => {
    setFieldPolygons((prev) =>
      prev.map((polygon) => {
        return checkTernaryCondition(
          polygon.fieldId === fieldId,
          {
            ...polygon,
            fieldId: newFieldId,
            fieldName: fieldName,
            isEditable: false,
            polygonOptions: null,
          },
          polygon,
        );
      }),
    );
  };

  const updateFieldInfoWindows = (fieldId) => {
    setFieldInfoWindows((prev) =>
      prev.filter((infoWindow) => infoWindow.fieldId !== fieldId),
    );
  };

  const showSuccessToast = (fieldName) => {
    toast(
      <CustomSnackbar
        type="success"
        message={`Field ${fieldName} was added successfully`}
      />,
      toastStyle,
    );
  };

  const showErrorToast = (fieldName) => {
    toast(
      <CustomSnackbar
        type="error"
        message={`Field ${fieldName} could not be added`}
      />,
      toastStyle,
    );
  };

  /**
   * @description function to update the form edit switch state
   * @param {Event} event
   * @param {boolean} extendSession
   */
  const handleSwitchChange = (event, extendSession = false) => {
    const switchSelected = event.target.checked;

    /* istanbul ignore else */
    if (+currentFarmId > 0) {
      axios
        .put(UPDATE_FARM_STATUS, {
          farmId: currentFarmId,
          projectId: projectIdValue,
          participantId: participantIdValue,
          lockStatus: switchSelected,
        })
        .then(() => {
          setSwitchState(switchSelected);
          setIsEditable(switchSelected);
          if (switchSelected) {
            resetTimer();
            start();
          } else {
            pause();
          }
        })
        /* istanbul ignore next */
        .catch((err) => {
          const customErrorMsg = err?.response?.data?.message;
          setSwitchState(false);
          setIsEditable(false);
          setIsInactiveTimerModalOpen(false);
          setIsInactiveModalOpen(false);
          pause();
          /* istanbul ignore else */
          if (!isEmpty(customErrorMsg)) {
            toast(
              <CustomSnackbar
                type="error"
                message={err?.response?.data?.message}
              />,
              toastStyle,
            );
            /* istanbul ignore else */
            if (extendSession) {
              setShowBlockedModal(true);
            }
          }
        });
    }
  };

  /* istanbul ignore next */
  const checkFieldAreaExceeding = (farmArea, fieldArea) => {
    const existingFieldsArea = getCurrentFarmFieldsArea();
    const updatedArea = existingFieldsArea + fieldArea;

    // Note: We are considering a buffer size of 10% on farm area, hence checking with farmArea * 1.1 (This would be -> (farmArea + ((10/100) * farmArea)))
    if (updatedArea > farmArea * 1.1) {
      const errorMsg = getFieldAreaExceedingError(updatedArea, farmArea);
      toast(<CustomSnackbar type={'error'} message={errorMsg} />, toastStyle);
    }
  };

  /* istanbul ignore next */
  const validateFieldBoundaryAndGetFieldPolygonDataId = (polygon) => {
    const fieldPolygonData = polygon.map((point) => ({
      longitude: point.lng,
      latitude: point.lat,
    }));

    const initialPoint = fieldPolygonData[0];
    return axios
      .post(VALIDATE_FIELD_SPATIAL_DATA, [...fieldPolygonData, initialPoint])
      .then((response) => {
        if (response.data.status === SUCCESS) {
          return {
            spatialPolygonDataId: response.data.data.spatialPolygonDataId,
            errorMessage: null,
          };
        } else {
          return {
            spatialPolygonDataId: null,
            errorMessage: response.data.message,
          };
        }
      })
      .catch(() => {
        return {
          spatialPolygonDataId: null,
          errorMessage: 'Error validating field boundary',
        };
      });
  };

  /* istanbul ignore next */
  const validateFieldBoundaryAndCreateField = (
    fieldBoundary,
    errorMessages,
    existingFieldNames,
    addFieldUrl,
  ) => {
    let fieldValidationPromise =
      validateFieldBoundaryAndGetFieldPolygonDataId(fieldBoundary);
    return fieldValidationPromise.then(
      ({ spatialPolygonDataId, errorMessage }) => {
        if (errorMessage) {
          errorMessages.add(errorMessage);
          return Promise.resolve({});
        }

        const newFieldName = getNextName(
          existingFieldNames,
          GLOBAL_STRING_CONSTANTS.default_field_name_prefix,
        );
        existingFieldNames.push(newFieldName);
        const area = getPolygonArea(fieldBoundary);
        const emptyData = {
          enrollmentInstanceId: Number(enrollmentInstanceId),
          projectCycleId: Number(projectCycleId),
          fieldName: newFieldName,
          formStatus: 'In Progress',
          fsaFieldId: '',
          fsaTractId: '',
          /*         csafImplemented: '', */
          activityList: [],
          totalFieldArea: +convertSquareMetersToAcres(area),
          /*      csafUpdatedBySystem: false,
              farmCsafUpdatedByField: false,
              isConsistentLanduse20Yrs: '',
              landUseTypeId: '',*/
          fieldHistoricLanduseList: [],
          spatialPolygonDataId: spatialPolygonDataId,
        };
        return axios.post(addFieldUrl, emptyData);
      },
    );
  };

  const displayFieldUploadError = (errorMessages) => {
    if (errorMessages.size > 0) {
      toast(
        <CustomSnackbar
          type={'error'}
          message={
            errorMessages.size > 1
              ? 'Fields Validations Failed with multiple errors'
              : errorMessages.values().next().value
          }
        />,
        toastStyle,
      );
    }
  };

  const addFieldsFromShapeFileErrorHandler = (error) => {
    const errorMessage = error?.response?.data?.errorCodeMsg;
    if (errorMessage === ENROLLMENT_SUBMITTED_MESSAGE)
      NavigateOutOfEnrollment();
    else
      toast(
        <CustomSnackbar type={'error'} message={`Fields could not be added`} />,
        toastStyle,
      );
  };

  /* istanbul ignore next */
  const addFieldsFromShapeFile = (fieldBoundaries) => {
    // check for overlapping, if any polygon overlaps stop adding new fields
    for (const polygonBoundary of fieldBoundaries) {
      if (isPolygonOverlapping(polygonBoundary)) {
        const errorMsg = PAGE_CONTENT.overlappingBoundaryError;
        toast(<CustomSnackbar type={'error'} message={errorMsg} />, toastStyle);
        return;
      }
    }

    // Show a toast if area exceeds farmArea
    const farmArea = Number(currentFarmInfo.totalFarmAcreage);
    const fieldAreas = fieldBoundaries.map((boundary) =>
      Number(convertSquareMetersToAcres(getPolygonArea(boundary))),
    );
    const totalFieldArea = fieldAreas.reduce(
      (total, area) => total + Number(area),
      0,
    );
    const updatedArea = (currentFarmFieldsArea ?? 0) + totalFieldArea;
    const areaExceeding = updatedArea > farmArea;
    // Sum of areas of existing fields exceeds farmArea
    if (areaExceeding) {
      const errorMsg = getFieldAreaExceedingError(updatedArea, farmArea);
      toast(<CustomSnackbar type={'error'} message={errorMsg} />, toastStyle);
      return;
    }

    const farmIndex = farmInfo.findIndex((farm) => farm.id === currentFarmId);
    if (farmIndex === -1) return;
    const url = `${PARTICIPANT_REPORT_FIRST_PART}/enroll/general-field-info?farmId=${currentFarmId}&participantId=${participantId}&projectId=${projectId}`;

    let existingFieldNames = farmInfo?.flatMap((farm) =>
      farm.fields?.map((field) => field.value),
    );

    let errorMessages = new Set();
    const addFieldRequests = fieldBoundaries.map((fieldBoundary) => {
      return validateFieldBoundaryAndCreateField(
        fieldBoundary,
        errorMessages,
        existingFieldNames,
        url,
      );
    });

    setLoaderCounter((prev) => prev + 1);
    Promise.all(addFieldRequests)
      .then((responses) => {
        responses.forEach((response, index) => {
          if (response.data) {
            setFarmInfo((prevInfo) => {
              return prevInfo.map((farm, index) => {
                if (index === farmIndex) {
                  return {
                    ...farm,
                    fields: [
                      ...farm.fields,
                      {
                        id: response.data.fieldId,
                        value: response.data.fieldName,
                        status: response.data.formStatus,
                        info: response.data.fieldName,
                        isApiPopulated: true,
                      },
                    ],
                  };
                }
                return farm;
              });
            });

            const newPolygon = {
              fieldId: response.data.fieldId,
              fieldName: response.data.fieldName,
              farmId: farmInfo[farmIndex].id,
              fieldBoundaries: fieldBoundaries[index],
            };
            setFieldPolygons((prevPolygons) => [...prevPolygons, newPolygon]);
            setFieldIdsAddedFromMap((prev) => [...prev, response.data.fieldId]);
            setMapCenter(newPolygon.fieldBoundaries[0]);
          }
        });
        setFarmIdsWithExistingFields((prev) => [...prev, currentFarmId]);
        setHasNotApprovedFarmOrFields(true);
        fetchParticipantData(false);
      })
      .catch(addFieldsFromShapeFileErrorHandler)
      .finally(() => {
        setLoaderCounter((prev) => prev - 1);
        displayFieldUploadError(errorMessages);
      });
  };

  const removeFieldPolygon = (fieldId) => {
    setFieldPolygons((prev) =>
      prev.filter((polygon) => polygon.fieldId !== fieldId),
    );
    setFieldInfoWindows((prev) =>
      prev.filter((infoWindow) => infoWindow.fieldId !== fieldId),
    );
    setFieldSelectionInProgress(false);
  };

  const setDummyLoading = (fieldId, position, _fieldName, farmId) => {
    const infoWindow = {
      fieldId: fieldId,
      position: position,
      farmId: farmId,
      content: <LoadingInfoWindow />,
      onClose: () => removeFieldPolygon(fieldId),
    };
    setFieldInfoWindows((prev) => [...prev, infoWindow]);
  };

  /**
   * @description This function calculates the area of a polygon using google maps api
   * @param polygonCoordinates is an array of objects each having a pair of lat and lng values(Eg: [{lat: number, lng: number}])
   * @returns area of polygon in square meters -> can be converted to required units(acres) during usage
   */
  const getPolygonArea = (polygonCoordinates) =>
    window.google.maps.geometry.spherical.computeArea(polygonCoordinates);

  /* istanbul ignore next */
  const handlePolygonEdit = (fieldId, newBoundaries) => {
    if (isPolygonOverlapping(newBoundaries)) {
      removeFieldPolygon(fieldId);
      const errorMsg = PAGE_CONTENT.overlappingBoundaryError;
      toast(<CustomSnackbar type={'error'} message={errorMsg} />, toastStyle);
      return;
    }
    const currentFarmArea = currentFarmInfo?.totalFarmAcreage ?? 0;
    const fieldArea = convertSquareMetersToAcres(getPolygonArea(newBoundaries));
    // Note: We are allowing a buffer of 10% on the farmArea, eg: if farmArea is 10acres, fieldArea can be <=11acres(10acres + 10% of 10acres)
    const isFieldBiggerThanFarm = fieldArea > Number(currentFarmArea) * 1.1;

    setFieldPolygons((prev) =>
      prev.map((polygon) => {
        if (polygon.fieldId === fieldId) {
          return {
            ...polygon,
            fieldBoundaries: newBoundaries,
            polygonOptions: isFieldBiggerThanFarm && errorPolygonOptions,
          };
        }
        return polygon;
      }),
    );

    setFieldInfoWindows((prev) =>
      prev.map((infoWindow) => {
        if (infoWindow.fieldId === fieldId) {
          return {
            ...infoWindow,
            position: getTopMostCoordinate(newBoundaries),
            content: (
              <InfoWindow
                fieldArea={fieldArea}
                totalFarmArea={+currentFarmArea}
                onAccept={(newName) => {
                  addFieldToFarm(
                    fieldId,
                    newName,
                    currentFarmIndex,
                    newBoundaries,
                  );
                  setIsFormUnmounted({
                    currentPage: null,
                    flag: false,
                    id: 0,
                  });
                  setSubmitData({
                    currentPage: null,
                    data: null,
                    currentId: null,
                  });
                }}
                onDelete={() => removeFieldPolygon(fieldId)}
                loadingState={false}
              />
            ),
          };
        }
        return infoWindow;
      }),
    );
  };

  /* istanbul ignore next */
  const handleNewPolygon = (fieldBoundaries) => {
    const currentFarmIndexValue = farmInfo.findIndex(
      (farm) => farm.id === currentFarmId,
    );
    if (currentFarmIndexValue === -1) return;
    const currentFarmArea = currentFarmInfo?.totalFarmAcreage ?? 0;
    const fieldArea = convertSquareMetersToAcres(
      getPolygonArea(fieldBoundaries),
    );

    // Note: We are allowing a buffer of 10% on the farmArea, eg: if farmArea is 10acres, fieldArea can be <=11acres(10acres + 10% of 10acres)
    const isFieldBiggerThanFarm = fieldArea > Number(currentFarmArea) * 1.1;

    const newFieldId = uniqueId();
    let existingFieldNames = farmInfo?.flatMap((farm) =>
      farm.fields?.map((field) => field.value),
    );
    const newFieldName = getNextName(
      existingFieldNames,
      GLOBAL_STRING_CONSTANTS.default_field_name_prefix,
    );
    const newPolygon = {
      fieldId: newFieldId,
      fieldName: newFieldName,
      farmId: currentFarmId,
      fieldBoundaries: fieldBoundaries,
      isEditable: true,
      isClickable: false,
      polygonOptions: isFieldBiggerThanFarm && errorPolygonOptions,
      isFieldBiggerThanFarm: isFieldBiggerThanFarm,
    };

    if (isPolygonOverlapping(newPolygon.fieldBoundaries)) {
      const errorMsg = PAGE_CONTENT.overlappingBoundaryError;
      toast(<CustomSnackbar type={'error'} message={errorMsg} />, toastStyle);
    } else {
      setFieldPolygons((prevPolygons) => [...prevPolygons, newPolygon]);
      setDummyLoading(
        newFieldId,
        getTopMostCoordinate(fieldBoundaries),
        newFieldName,
        currentFarmId,
      );

      setFieldSelectionInProgress(true);
      setTimeout(() => {
        setFieldInfoWindows((prev) =>
          prev.map((infoWindow) =>
            infoWindow.fieldId === newFieldId
              ? {
                  ...infoWindow,
                  position: getTopMostCoordinate(newPolygon.fieldBoundaries),
                  isFieldBiggerThanFarm: isFieldBiggerThanFarm,
                  content: (
                    <InfoWindow
                      fieldArea={fieldArea}
                      totalFarmArea={+currentFarmArea}
                      onAccept={(newName) => {
                        addFieldToFarm(
                          newFieldId,
                          newName,
                          currentFarmIndexValue,
                          newPolygon.fieldBoundaries,
                        );
                        setIsFormUnmounted({
                          currentPage: null,
                          flag: false,
                          id: 0,
                        });
                        setSubmitData({
                          currentPage: null,
                          data: null,
                          currentId: null,
                        });
                      }}
                      onDelete={() => removeFieldPolygon(newFieldId)}
                      loadingState={false}
                    />
                  ),
                  onClose: () => removeFieldPolygon(newFieldId),
                }
              : infoWindow,
          ),
        );
      }, 1000);
    }
  };

  const removePolygonsAndInfoWindowsWithError = () => {
    setFieldPolygons((prev) =>
      prev.filter((polygon) => !polygon.isFieldBiggerThanFarm),
    );
    setFieldInfoWindows((prev) =>
      prev.filter((infoWindow) => !infoWindow.isFieldBiggerThanFarm),
    );
    setFieldSelectionInProgress(false);
  };

  // Using tempBoundaries state here since direct use of fieldBoundaries wasn't updating the handler passed to map, causing some fields to be ignored for overlapping check.
  useEffect(() => {
    /* istanbul ignore next */
    if (tempBoundaries && selectedFieldAddType !== null) {
      handleNewPolygon(tempBoundaries);
      setTempBoundaries(null);
    }
  }, [tempBoundaries]);

  const getSwitchState = () => {
    /* istanbul ignore else */
    if (
      !isEmpty(currentFarmId) &&
      !isEmpty(projectIdValue) &&
      !isEmpty(participantIdValue)
    ) {
      axios
        .get(
          `${FETCH_USER_LOCK_DETAILS}/${currentFarmId}?internalProjectId=${projectIdValue}&participantOwnerId=${participantIdValue}`,
        )
        .then((response) => {
          setActiveUser(response.data);
          const { activeUserId } = response.data;
          const disableSwitch =
            !isEmpty(activeUserId) && +activeUserId != +userId;
          setIsSwitchDisabled(disableSwitch);
          if (disableSwitch) {
            setSwitchState(false);
          } else {
            setSwitchState(+activeUserId === +userId);
          }
        })
        .catch(() => {
          /* istanbul ignore next */
          displayToast('error', ERROR_MSGS.FETCH);
        });
    }
  };

  useEffect(() => {
    /* istanbul ignore else */
    if (+currentFarmId > 0) {
      getSwitchState();
    }
  }, [currentFarmId]);

  useEffect(() => {
    axios
      .get(FETCH_FARM_LOCKOUT_TIME)
      .then((response) => {
        setIdleTimeout(response.data);
      })
      .catch(() => {
        /* do api error handling here */
      });

    return () => {
      if (switchState) {
        handleSwitchChange({ target: { checked: false } });
      }
    };
  }, []);

  const populatePolygons = (farmDetails) => {
    const polygonsList = [];
    farmDetails.forEach((farm) => {
      farm.fieldDetails.forEach((field) => {
        if (field.fieldBoundaries) {
          const newPolygon = {
            fieldId: field.fieldId,
            fieldName: field.fieldName,
            farmId: farm.farmId,
            fieldBoundaries: parseBoundaries(field.fieldBoundaries),
          };
          polygonsList.push(newPolygon);
        }
      });
    });
    setFieldPolygons(polygonsList);
  };

  const updateFarmFieldName = ({ farmId, fieldId, name }) => {
    const farmIndex = farmInfo.findIndex((farm) => farm.id === farmId);

    if (farmIndex >= 0) {
      const fieldsList = farmInfo[farmIndex].fields;
      const fieldIndex = fieldsList.findIndex((field) => field.id === fieldId);

      if (fieldIndex >= 0) {
        const fieldToBeUpdated = fieldsList[fieldIndex];
        const updatedField = { ...fieldToBeUpdated, value: name };

        setFarmInfo((prevFarmInfo) => {
          return prevFarmInfo.map((farm, index) => {
            return farmIndex === index
              ? {
                  ...farm,
                  fields: farm.fields.map((field, indexValue) => {
                    /* istanbul ignore else */
                    if (fieldIndex === indexValue) {
                      return updatedField;
                    }
                    return field;
                  }),
                }
              : farm;
          });
        });

        setFieldPolygons((prevPolygons) => {
          return prevPolygons.map((polygon) => {
            return checkTernaryCondition(
              polygon.fieldId === fieldId,
              { ...polygon, fieldName: name },
              polygon,
            );
          });
        });
      }
    }
  };

  const selectedFieldBoundaries = useMemo(() => {
    if (selectedFieldId === null) return '';
    else {
      const selectedFieldPolygon = fieldPolygons.find(
        (polygon) => polygon.fieldId === selectedFieldId.id,
      );
      return selectedFieldPolygon?.fieldBoundaries || [];
    }
  }, [selectedFieldId, fieldPolygons]);

  // Note: This memo creates a variable with the current polygon data of the selected field with map bounds required to fit the polygon in the map, used inside "View map" tab of field info form
  const currentFieldPolygonData = useMemo(() => {
    if (selectedFieldId === null) return { boundaries: [], bounds: [] };
    else {
      const currentPolygonData = fieldPolygons.find(
        (polygon) => polygon.fieldId === selectedFieldId.id,
      );
      const gmapBounds = new window.google.maps.LatLngBounds();
      currentPolygonData?.fieldBoundaries.forEach((bound) => {
        gmapBounds.extend(bound);
      });
      return {
        polygon: currentPolygonData ? [currentPolygonData] : [],
        bounds: gmapBounds,
      };
    }
  }, [selectedFieldId, fieldPolygons]);

  const selectedFieldName = useMemo(() => {
    if (selectedFieldId && farmInfo) {
      return (
        farmInfo
          .find((farm) => farm?.id === currentFarmId)
          ?.fields?.find((field) => field.id === selectedFieldId.id)?.value ||
        ''
      );
    }
    return '';
  }, [selectedFieldId, farmInfo]);

  /* istanbul ignore next */
  const uploadShapeFiles = (files) => {
    const formData = new FormData();
    formData.append('file', files[0]);

    setLoaderCounter((prev) => prev + 1);
    axios
      .post(BOUNDARY_SHAPE_FILE_UPLOAD, formData)
      .then((resp) => {
        const polygons = formatGeoJson(resp.data);
        addFieldsFromShapeFile(polygons);
        setShapeFileUploadModalOpen(false);
      })
      .catch((error) => {
        const errorMsg = getApiError(
          error.response?.data?.errorCodeMsg ||
            error.response?.data?.message ||
            'Error',
          SHAPE_FILE_UPLOAD_CONTENT.infoText,
        );
        const messagesList = errorMsg.split('\n');
        setFilesUploadError({
          flag: true,
          messages: messagesList,
        });
      })
      .then(({ data }) => {
        if (data.status !== SUCCESS && data.message) {
          toast(
            <CustomSnackbar type="error" message={data.message} />,
            toastStyle,
          );
        }
      })
      .catch(() => {
        /* istanbul ignore else */
        displayToast('error', ERROR_MSGS.UNEXPECTED);
      })
      .finally(() => setLoaderCounter((prev) => prev - 1));
  };

  const extractFarmIdsWithExistingFields = (farmList) => {
    let farmIdsHavingFields = [];
    if (farmList && farmList.length > 0) {
      farmList.forEach((farm) => {
        if (farm.fieldDetails?.length > 0)
          farmIdsHavingFields.push(farm.farmId);
      });
    }
    setFarmIdsWithExistingFields(farmIdsHavingFields);
  };

  const openFieldInformation = (field) => {
    /* istanbul ignore else */
    if (+currentFarmId !== +field.farmId) {
      handleSwitchChange({ target: { checked: false } });
    }
    setRightSectionId(2);
    setCurrentFarmId(field.farmId);
    setExpandedFarmId(field.farmId);
    setSelectedFieldId({ id: field.id, name: field.name });
    setShowFieldInfoForm(true);
  };

  /* istanbul ignore next */
  const handleFieldBoundaryClick = (boundaryData) => {
    const drawMode = getMapDrawMode();
    if (drawMode === 'polygon') return;
    const fieldId = boundaryData.fieldId;
    const fieldName = boundaryData.fieldName;
    const farmId = boundaryData.farmId;

    openFieldInformation({ id: fieldId, name: fieldName, farmId: farmId });
  };

  const getMapDrawMode = () => {
    /* istanbul ignore else */
    if (
      expandedFarmId === -1 ||
      currentFarmId == -1 ||
      fieldSelectionInProgress ||
      !switchState
    )
      return null;

    switch (selectedFieldAddType) {
      case fieldAddTypes[1]:
        return 'polygon';
      case fieldAddTypes[2]:
        return 'marker';
      case fieldAddTypes[0]:
        return null;
      default:
        return null;
    }
  };

  // Call field info fetch on tab change from 1 to 0
  const handleFieldInfoTabChange = (tabIndex) => {
    if (tabIndex === 0) {
      setTriggerFieldInfoFetch(true);
    } else if (tabIndex === 1) {
      fetchParticipantData(false);
    }
  };

  useEffect(() => {
    if (
      farmInfoCurrentTab === 1 &&
      !farmIdsWithExistingFields.includes(currentFarmId)
    ) {
      setFieldAddTypeModalOpen(true);
    }
    if (farmInfoCurrentTab === 0) setSelectedFieldAddType(null);
  }, [farmInfoCurrentTab]);

  const addFarmData = async (payload) => {
    const URL = ADD_FARM.replace('{participantId}', participantId);
    return axios.post(URL, payload);
  };

  const getUpdatedFarmDetails = async () => {
    return axios.get(
      `${PARTICIPANT_PROFILE}?enrollmentType=PARTICIPANT&projectId=${projectId}&participantId=${participantId}`,
    );
  };

  const addFarmhandler = () => {
    setLoaderCounter((prev) => prev + 1);
    /* istanbul ignore else */
    if (switchState) {
      handleSwitchChange({ target: { checked: false } });
    }
    setUserActivityDetected(false);
    const existingFarmNames = farmInfo.map((farm) => farm.value);
    const farmName = getNextName(
      existingFarmNames,
      GLOBAL_STRING_CONSTANTS.default_farm_name_prefix,
    );
    addFarmData(
      getAddFarmRequestBody({
        internalProjectId: projectId,
        enrollmentInstanceId: enrollmentInstanceId,
        farmName: farmName,
        projectCycleId: projectCycleId,
      }),
    )
      .then(async (farmResponse) => {
        setAddedFromUiFarmIds((prev) => [...prev, farmResponse.data.farmId]);
        return getUpdatedFarmDetails().then((response) => {
          const currentFarmIdValue = farmResponse.data.farmId;
          const updatedFarmDetails = getFarmsWithSortedFields(
            response.data.farmDetails,
          );
          updateFarmInfo(updatedFarmDetails);
          setRightSectionId(2);
          setFarmInfoCurrentTab(0);
          setCurrentFarmId(currentFarmIdValue);
          setExpandedFarmId(currentFarmIdValue);
          setShowFieldInfoForm(false);
        });
      })
      .catch((error) => {
        const errorMessage = error?.response?.data?.errorCodeMsg;
        if (errorMessage === ENROLLMENT_SUBMITTED_MESSAGE)
          NavigateOutOfEnrollment();
      })
      .finally(() => setLoaderCounter((prev) => prev - 1));
  };

  const addFarmClickHandler = useThrottle(addFarmhandler, 500);

  const updateFarmId = (farmId) => {
    /* istanbul ignore else */
    if (+currentFarmId !== +farmId && switchState) {
      handleSwitchChange({ target: { checked: false } });
    }
    setCurrentFarmId(farmId);
  };
  /**
   * Handles the redirection logic for the "Save and Continue" button based on the type of the in-progress item.
   *
   * @param {Object} inProgressItem
   */
  /* istanbul ignore next */
  const redirectHandlerForSaveAndContinueBtn = (inProgressItem) => {
    if (inProgressItem.type === 'field') {
      setSelectedFieldId({
        id: inProgressItem.id,
        name: inProgressItem.value,
      });
      setCurrentFarmId(inProgressItem.farmId);
      setExpandedFarmId(inProgressItem.farmId);
      setShowFieldInfoForm(true);
      setFieldInfoTab(0);
      /* istanbul ignore else */
      if (inProgressItem.farmId !== currentFarmId && switchState) {
        handleSwitchChange({ target: { checked: false } });
      }
    } else if (inProgressItem.type === 'farm') {
      setCurrentFarmId(inProgressItem.id);
      setExpandedFarmId(inProgressItem.id);
      setShowFieldInfoForm(false);
      setFarmInfoCurrentTab(0);
      /* istanbul ignore else */
      if (inProgressItem.id !== currentFarmId && switchState) {
        handleSwitchChange({ target: { checked: false } });
      }
    } else if (inProgressItem.type === 'profile') {
      goToProfile();
    }
  };

  const handleBlockModalClose = () => {
    setShowBlockedModal(false);
    getSwitchState();
  };

  /**
   * Handles the save and continue action for the current farm.
   *
   * @param {boolean} handlingFarm - Indicates if the farm webform is being handled.
   */
  const saveAndContinueHandler = (handlingFarm) => {
    fetchEnrollmentCompletionDetails();
    const currentFarm = farmInfo.filter((farm) => farm.id === currentFarmId)[0];
    let inProgressItem = handleNextInProgrssSwitcher({
      updatedFarmInfo: farmInfo,
      currentFarm: currentFarm,
      ...(selectedFieldId && { currentField: { fieldId: selectedFieldId.id } }),
      isFarmWebform: handlingFarm,
      participantProfileStatus: participantProfileStatus,
      handleSaveAndContinueOnMapView: true,
    });
    redirectHandlerForSaveAndContinueBtn(inProgressItem);
  };

  useEffect(() => {
    setSectionList([
      {
        id: 1,
        leftSection: (
          <LeftMenuSubsection
            heading={LEFT_MENU_SECTION_HEADERS.profileSection}
            fieldData={participantInfo}
            setRightSectionId={setRightSectionId}
            updateFarmFieldName={updateFarmFieldName}
            currentFarmId={currentFarmId}
            setCurrentFarmId={setCurrentFarmId}
            setOpenMapView={setOpenMapView}
            setSelectedFieldId={setSelectedFieldId}
            setShowFieldInfoForm={setShowFieldInfoForm}
            setFieldAddTypeModalOpen={setFieldAddTypeModalOpen}
            setFarmInfoCurrentTab={setFarmInfoCurrentTab}
            setExpandedFarmId={setExpandedFarmId}
            farmsIdsWithExistingFields={farmIdsWithExistingFields}
            farmInfo={farmInfo}
            setFarmInfo={setFarmInfo}
            selectedFieldId={selectedFieldId}
            setFieldPolygons={setFieldPolygons}
            rightSectionId={rightSectionId}
            expandedFarmId={expandedFarmId}
            setAddFarmClicked={setAddFarmClicked}
            setIsTabSwitched={setIsTabSwitched}
            setIsFormUnmounted={setIsFormUnmounted}
            resetFieldViewTab={resetFieldViewTab}
            hideSection={isParticipantContributor}
            handleAccordianSummaryClick={handleAccordianSummaryClick}
            isInBaselinePage={false}
            isInsideActivityReporting={false}
          />
        ),
        rightSection: (
          <ProfileInfoWithTabber
            currentTab={profileInfoTab}
            setCurrentTab={setProfileInfoTab}
            tabList={[
              {
                title: 'Profile info',
                component: <ParticipantGenralInfoForm />,
              },
            ]}
          />
        ),
      },
      {
        id: 2,
        leftSection: (
          <>
            <LeftMenuSubsection
              heading={LEFT_MENU_SECTION_HEADERS.farmAndFieldsSection}
              fieldData={farmInfo}
              setRightSectionId={setRightSectionId}
              updateFarmFieldName={updateFarmFieldName}
              currentFarmId={currentFarmId}
              setCurrentFarmId={updateFarmId}
              setOpenMapView={setOpenMapView}
              setSelectedFieldId={setSelectedFieldId}
              setShowFieldInfoForm={setShowFieldInfoForm}
              setFieldAddTypeModalOpen={setFieldAddTypeModalOpen}
              farmInfoCurrentTab={farmInfoCurrentTab}
              setFarmInfoCurrentTab={setFarmInfoCurrentTab}
              setExpandedFarmId={setExpandedFarmId}
              farmsIdsWithExistingFields={farmIdsWithExistingFields}
              farmInfo={farmInfo}
              setFarmInfo={setFarmInfo}
              selectedFieldId={selectedFieldId}
              setFieldPolygons={setFieldPolygons}
              rightSectionId={rightSectionId}
              expandedFarmId={expandedFarmId}
              setAddFarmClicked={setAddFarmClicked}
              setIsTabSwitched={setIsTabSwitched}
              setCurrentFarmLocation={setCurrentFarmLocation}
              showFieldInfoForm={showFieldInfoForm}
              setIsFormUnmounted={setIsFormUnmounted}
              setIsFieldDeleted={setIsFieldDeleted}
              resetFieldViewTab={resetFieldViewTab}
              enrolledFarmIds={enrolledFarmIds}
              enrolledFieldIds={enrolledFieldIds}
              isParticipantContributor={isParticipantContributor}
              handleViewMapClick={handleViewMapClick}
              handleAccordianSummaryClick={handleAccordianSummaryClick}
              handleAccordianItemClick={handleAccordianItemClick}
              addSectionLabel={PAGE_CONTENT.noNeed}
              addSectionHandler={addFarmClickHandler}
              isInBaselinePage={false}
              isInsideActivityReporting={false}
              isAddFarmDisabled={loaderCounter > 0}
            />
            {switchState && (
              <AddFieldsModal
                acceptedFilesInfo={{
                  fileNotSupportedErrorText:
                    SHAPE_FILE_UPLOAD_CONTENT.fileNotSupportedError,
                  infoText: SHAPE_FILE_UPLOAD_CONTENT.infoText,
                  mimeType: SHAPE_FILE_UPLOAD_CONTENT.acceptedMimeType,
                  extensions: SHAPE_FILE_UPLOAD_CONTENT.acceptedExtensions,
                  maxFiles: SHAPE_FILE_UPLOAD_CONTENT.maxNoOfFiles,
                  maxFileSize: SHAPE_FILE_UPLOAD_CONTENT.maxFileSize,
                  overlappingFieldsInfo:
                    SHAPE_FILE_UPLOAD_CONTENT.fieldOverlappingInfoText,
                }}
              />
            )}

            <FarmLockModal
              primaryButtonMarginTop="1rem"
              dialogSx={FarmLockingSx}
              isOpen={isInactiveTimerModalOpen}
              onClose={() => {
                setIsInactiveTimerModalOpen(false);
              }}
              title={FARM_LOCK_MODALS_DATA.LOCKING_SOON_MODAL.HEADING}
              onExtend={onExtendSession}
              subtext={
                <TimerContent
                  warningTimeout={promptBeforeIdle}
                  handleLogout={onUserIdle}
                  timeoutText={FARM_LOCK_MODALS_DATA.LOCKING_SOON_MODAL.BODY}
                  styles={{ margin: 0, color: DARK_CHARCOAL }}
                  nextLineTimeOutText
                />
              }
              subtitleSx={FarmLockModalSubtitleSx}
            />
            <FarmLockModal
              primaryButtonMarginTop="1rem"
              dialogSx={FarmExtendSx}
              isOpen={isInactiveModalOpen}
              onClose={() => {
                setIsInactiveModalOpen(false);
                pause();
              }}
              title={FARM_LOCK_MODALS_DATA.FORM_LOCKED_INACTIVITY.HEADING}
              onExtend={onExtendSession}
              subtext={FARM_LOCK_MODALS_DATA.FORM_LOCKED_INACTIVITY.BODY}
              subtitleSx={FarmLockModalSubtitleSx}
            />
            <DialogBox
              dialogActions
              isOpen={showBlockedModal}
              primaryButtonsMarginTop={'1rem'}
              onCancel={(_event, reason) => {
                if (reason && reason === 'backdropClick') return;
                handleBlockModalClose();
              }}
              subtitle={getFarmLockedTooltip(
                true,
                userRoles?.includes(ADMIN) ||
                  userRoles?.includes(ORIGINATION_MANAGER),
                activeUser.name,
                true,
              )}
              onConfirm={handleBlockModalClose}
              acceptCtnLabel={FARM_LOCK_MODALS_DATA.BUTTONS.CLOSE}
              isCloseEnabled={true}
              title={FARM_LOCK_MODALS_DATA.LOCKED_BY_ANOTHER_USER.HEADING}
              showAcceptCtnLabel={true}
              subtitleSx={FarmLockModalSubtitleSx}
            />
          </>
        ),
        rightSection: checkTernaryCondition(
          selectedFieldId !== null && showFieldInfoForm,
          <FieldInfoWithTabber
            currentTab={fieldInfoTab}
            name={selectedFieldName}
            handleSwitchChange={handleSwitchChange}
            setCurrentTab={(newTab) => setFieldInfoTab(newTab)}
            handleTabChange={handleFieldInfoTabChange}
            tabList={[
              {
                title: 'Field info',
                component: (
                  <ParticipantGeneralFieldInfoForm
                    id={selectedFieldId?.id}
                    participantId={participantId}
                    name={selectedFieldName}
                    projectId={projectId}
                    isEditModeOn={isEditModeOn}
                    selectedFieldId={selectedFieldId}
                    selectedFieldBoundaries={selectedFieldBoundaries}
                    handleSwitchChange={handleSwitchChange}
                    isEditable={isEditable}
                    isSwitchDisabled={isSwitchDisabled}
                    setIsSwitchDisabled={setIsSwitchDisabled}
                    setParticipantIdValue={setParticipantIdValue}
                    setProjectIdValue={setProjectIdValue}
                    setSwitchState={setSwitchState}
                    switchState={switchState}
                    isBlockedModalVisible={showBlockedModal}
                  />
                ),
              },
              {
                title: 'Map view',
                component: (
                  <MapViewContainerWrapper>
                    <MapSearchContainer
                      additionalClassName="summary-map"
                      mapCenter={mapCenter}
                      fieldPolygons={currentFieldPolygonData.polygon}
                      mapControlOptions={mapControlOptions}
                      style={fieldMapViewStyle}
                      mapBounds={currentFieldPolygonData.bounds}
                      showPolygonArea={true}
                    />
                    <NavigationBtnWrapper>
                      <Divider />
                      <ButtonContainer>
                        <UserNavigationButtonsV2
                          enableImplicitHandlerForSubmitAllFormsButton
                          handleSave={() => {
                            // do nothing for now
                          }}
                          handleContinue={() => {
                            saveAndContinueHandler(false);
                          }}
                          handleSubmitAllFarms={() => {
                            /* do nothing here */
                          }}
                          disableSubmitAllFarms={
                            !(
                              enrollmentDataCollectionCompleted &&
                              hasUnApprovedFarmsOrFields
                            )
                          }
                          disabledSave={!switchState}
                          disabledContinue={!switchState}
                        />
                      </ButtonContainer>
                    </NavigationBtnWrapper>
                  </MapViewContainerWrapper>
                ),
              },
            ]}
          />,
          <FarmWithMapTabs
            currentTab={farmInfoCurrentTab}
            handleSwitchChange={handleSwitchChange}
            setCurrentTab={(newTab) => {
              removePolygonsAndInfoWindowsWithError();
              setFarmInfoCurrentTab(newTab);
              if (newTab === 0) fetchParticipantData(false);
            }}
            tabList={[
              {
                title: 'Farm info',
                component: (
                  <ParticipantGeneralFarmInfo
                    handleSwitchChange={handleSwitchChange}
                    switchState={switchState}
                    isEditable={isEditable}
                    setIsEditable={setIsEditable}
                    isSwitchDisabled={isSwitchDisabled}
                    setIsSwitchDisabled={setIsSwitchDisabled}
                    setProjectIdValue={setProjectIdValue}
                    setParticipantIdValue={setParticipantIdValue}
                    setSwitchState={setSwitchState}
                    isBlockedModalVisible={showBlockedModal}
                  />
                ),
              },
              {
                title: 'Map view',
                component: (
                  <MapViewContainerWrapper>
                    <MultiFilesUploader
                      isOpen={shapeFileUploadModalOpen}
                      onCancel={() => setShapeFileUploadModalOpen(false)}
                      title={SHAPE_FILE_UPLOAD_CONTENT.modalTitle}
                      dragAndDropText={
                        SHAPE_FILE_UPLOAD_CONTENT.dragAndDropInsideText
                      }
                      cancelText={SHAPE_FILE_UPLOAD_CONTENT.cancelText}
                      uploadText={SHAPE_FILE_UPLOAD_CONTENT.uploadText}
                      acceptedFilesInfo={{
                        fileNotSupportedErrorText:
                          SHAPE_FILE_UPLOAD_CONTENT.fileNotSupportedError,
                        infoTextList: SHAPE_FILE_UPLOAD_CONTENT.infoTextList,
                        mimeType: SHAPE_FILE_UPLOAD_CONTENT.acceptedMimeType,
                        extensions:
                          SHAPE_FILE_UPLOAD_CONTENT.acceptedExtensions,
                        maxFiles: SHAPE_FILE_UPLOAD_CONTENT.maxNoOfFiles,
                        maxFileSize: SHAPE_FILE_UPLOAD_CONTENT.maxFileSize,
                      }}
                      onUpload={uploadShapeFiles}
                      apiError={filesUploadError}
                      setApiError={setFilesUploadError}
                    />
                    <FieldAddTypeUpdaters disableButtons={!switchState} />
                    <MapSearchContainer
                      additionalClassName="summary-map"
                      key={currentFarmId}
                      markersWithInfoWindows={
                        currentFarmId > -1 && expandedFarmId !== -1
                          ? fieldMarkers.filter(
                              (marker) => marker.farmId === currentFarmId,
                            )
                          : []
                      }
                      mapCenter={mapCenter}
                      fieldPolygons={checkTernaryCondition(
                        expandedFarmId !== -1 && currentFarmId !== -1,
                        fieldPolygons.filter(
                          (polygon) => polygon.farmId === currentFarmId,
                        ),
                        [],
                      )}
                      fieldInfoWindows={checkTernaryCondition(
                        expandedFarmId !== -1 && currentFarmId !== -1,
                        fieldInfoWindows.filter(
                          (infoWindow) => infoWindow.farmId === currentFarmId,
                        ),
                        [],
                      )}
                      mapControlOptions={mapControlOptions}
                      style={mapStyle}
                      handleNewPolygon={(fieldBoundaries) => {
                        setTempBoundaries(fieldBoundaries);
                      }}
                      drawMode={getMapDrawMode()}
                      mapBounds={mapBounds}
                      hasOnClickFunctionality
                      onPolygonClick={handleFieldBoundaryClick}
                      handlePolygonEdit={handlePolygonEdit}
                      showPolygonArea={true}
                    />
                    <NavigationBtnWrapper>
                      <Divider />
                      <ButtonContainer>
                        <UserNavigationButtonsV2
                          enableImplicitHandlerForSubmitAllFormsButton
                          handleSave={() => {
                            // do nothing for now
                          }}
                          handleContinue={() => {
                            saveAndContinueHandler(true);
                          }}
                          handleSubmitAllFarms={() => {
                            /* do nothing here */
                          }}
                          disableSubmitAllFarms={
                            !(
                              enrollmentDataCollectionCompleted &&
                              hasUnApprovedFarmsOrFields
                            )
                          }
                          disabledSave={!switchState}
                          disabledContinue={!switchState}
                        />
                      </ButtonContainer>
                    </NavigationBtnWrapper>
                  </MapViewContainerWrapper>
                ),
              },
            ]}
          />,
        ),
      },
    ]);
  }, [
    participantInfo,
    farmInfo,
    expandedFarmId,
    currentFarmId,
    openMapView,
    selectedFieldId,
    fieldPolygons,
    loading,
    selectedFieldName,
    selectedFieldBoundaries,
    farmInfoCurrentTab,
    showFieldInfoForm,
    fieldMarkers,
    fieldInfoWindows,
    selectedFieldAddType,
    filesUploadError,
    shapeFileUploadModalOpen,
    mapCenter,
    mapBounds,
    rightSectionId,
    fieldInfoTab,
    isProfileDataEditable,
    enrolledFarmIds,
    enrolledFieldIds,
    submitData,
    userActivityDetected,
    switchState,
    isSwitchDisabled,
    isInactiveTimerModalOpen,
    isInactiveModalOpen,
    showBlockedModal,
  ]);

  const fetchStateList = async () => {
    return axios
      .get(FETCH_STATE_LIST)
      .then((response) => {
        return response.data.map(({ stateId, stateName }) => ({
          stateId: stateId,
          label: stateName,
          value: stateName,
        }));
      })
      .catch(() => {
        /* istanbul ignore else */
        displayToast('error', ERROR_MSGS.FETCH);
      });
  };

  const fetchCountyList = async (stateId) => {
    if (!stateId) return;
    return axios
      .get(FETCH_COUNTY_LIST, { params: { stateId: stateId } })
      .then((response) => {
        const countyList = response.data.map((county) => ({
          countyId: county.countyId,
          label: county.countyName,
          value: county.countyName,
        }));
        return countyList;
      })
      .catch(() => {
        /* istanbul ignore else */
        displayToast('error', ERROR_MSGS.FETCH);
      });
  };

  const filterApprovedEnrollmentData = (farmsList) => {
    const enrolledFarms = [],
      enrolledFields = [];

    for (const farm of farmsList) {
      if (farm.enrolmentStatus === enrollmentInstanceStatus.approved) {
        enrolledFarms.push(farm.farmId);
      } else {
        setHasNotApprovedFarmOrFields(true);
      }

      const fieldsList = farm.fieldDetails;
      for (const field of fieldsList) {
        if (field.enrolmentStatus === enrollmentInstanceStatus.approved) {
          enrolledFields.push(field.fieldId);
        } else {
          setHasNotApprovedFarmOrFields(true);
        }
      }
    }

    setEnrolledFarmIds(enrolledFarms);
    setEnrolledFieldIds(enrolledFields);
  };
  /**
   * @description function to get the fields details of a farm
   * @param {Object} farm
   * @returns {Array}
   */
  const getFields = (farm) =>
    farm.fieldDetails?.map(
      ({
        fieldId,
        fieldName,
        formStatus,
        totalFieldArea,
        fieldBoundaries,
        enrolmentStatus,
        activityReportingStatus,
      }) => ({
        id: fieldId,
        value: fieldName,
        lat: '',
        lng: '',
        status: formStatus,
        info: '',
        isApiPopulated: true,
        totalArea: totalFieldArea,
        fieldBoundaries: fieldBoundaries,
        enrollmentStatus: enrolmentStatus,
        activityReportingStatus: activityReportingStatus,
      }),
    );

  const updateFarmInfo = (farms) => {
    setFarmInfo(
      farms.map((farm) => {
        return {
          id: farm.farmId,
          status: farm.farmStatus,
          formValidationFlag: farm.formValidationFlag,
          value: farm.farmName,
          isApiPopulated: true,
          fields: getFields(farm),
          activityReportingStatus: farm.activityReportingStatus,
          enrollmentStatus: farm.enrolmentStatus,
        };
      }),
    );
  };

  /**
   * Updates the enrollment status based on the fields of the provided farms.
   *
   * Iterates through the provided farms and checks their field details to determine
   * if any field is in progress. If any field is in progress, sets the enrollment
   * status to not completed. If no fields are in progress, sets the enrollment status
   * to completed.
   *
   * @param {Array} farmsWithSortedFields - An array of farm objects, each containing field details.
   */
  const updateEnrollMentStatusFromFields = (farmsWithSortedFields) => {
    const isEnrollmentCompleted = farmsWithSortedFields.every((farm) => {
      if (farm?.fieldDetails?.length > 0) {
        return !farm.fieldDetails.some(
          (field) => field?.formStatus === PAGE_CONTENT.IN_PROGRESS,
        );
      }
      return false;
    });
    setIsEnrollmentCompletedAllTogether(isEnrollmentCompleted);
  };

  /**
   * Fetches enrollment completion details for a participant.
   *
   * This function calls the participant profile API with an enrollment type of 'PARTICIPANT'
   * and checks the form status of the profile, farm, and field pages. It updates the state
   * accordingly based on the status of the participant and the fields within the farms.
   *
   * @function fetchEnrollmentCompletionDetails
   * @returns {void}
   */
  const fetchEnrollmentCompletionDetails = () => {
    axios
      .get(
        `${PARTICIPANT_PROFILE}?enrollmentType=PARTICIPANT&projectId=${projectId}&participantId=${participantId}`,
      )
      .then((response) => {
        const farmsWithSortedFields = getFarmsWithSortedFields(
          response.data.farmDetails,
        );
        const status = response.data.participantStatus;
        if (
          status === PAGE_CONTENT.IN_PROGRESS ||
          farmsWithSortedFields.length === 0
        ) {
          setIsEnrollmentCompletedAllTogether(false);
          return;
        }
        const farmFlag = farmsWithSortedFields.some(
          (farm) => farm.farmStatus === PAGE_CONTENT.IN_PROGRESS,
        );
        if (farmFlag) {
          setIsEnrollmentCompletedAllTogether(false);
        } else {
          updateEnrollMentStatusFromFields(farmsWithSortedFields);
        }
      });
  };
  // handles on load right section render for participant contirbutor and other roles
  const handleRightSectionInitialRender = (
    isInitialCall,
    farmsWithSortedFields,
  ) => {
    const firstFarmId = farmsWithSortedFields[0]?.farmId;
    /*Istanbul ignore else */
    if (isInitialCall) {
      if (isParticipantContributor) {
        setRightSectionId(2);
        setExpandedFarmId(firstFarmId);
        setCurrentFarmId(firstFarmId);
      } else {
        setRightSectionId(1);
      }
    }
  };
  const fetchParticipantData = (isInitialCall) => {
    if (projectId && participantId) {
      setLoaderCounter((prev) => prev + 1);
      axios
        .get(
          `${PARTICIPANT_PROFILE}?enrollmentType=PARTICIPANT&projectId=${projectId}&participantId=${participantId}`,
        )
        .then((response) => {
          setIsProfileDataEditable(
            !response.data?.participantProfileToBeDisabled,
          );
          const farmsWithSortedFields = getFarmsWithSortedFields(
            response.data.farmDetails,
          );
          handleRightSectionInitialRender(isInitialCall, farmsWithSortedFields);
          if (
            farmsWithSortedFields[0]?.fieldDetails?.length > 0 &&
            isInitialCall
          ) {
            setExpandedFarmId(farmsWithSortedFields[0].farmId);
            setIsEditModeOn(true);
          }
          const data = response.data;
          const status = data.participantStatus;
          filterApprovedEnrollmentData(farmsWithSortedFields);
          setParticipantProfileStatus(status);
          setParticipantInfo([
            {
              id: 1,
              status: checkTernaryCondition(
                status === null || status === PAGE_CONTENT.IN_PROGRESS,
                PAGE_CONTENT.in_progress,
                PAGE_CONTENT.complete,
              ),
              value: `${response.data.firstName} ${response.data.lastName}`,
            },
          ]);
          updateFarmInfo(farmsWithSortedFields);
          setFarmListIds(farmsWithSortedFields);
          populatePolygons(farmsWithSortedFields);
        })
        .catch((error) => {
          if (
            error?.response?.status === LANDING_PAGE_CONTENT.api_fail_status_401
          ) {
            navigate(pathName.participant.landingPage);
          }
          /* istanbul ignore else */
          displayToast('error', ERROR_MSGS.FETCH);
        })
        .finally(() => setLoaderCounter((prev) => prev - 1));
    } else {
      navigate(
        `${pathName.participant.landingPage}?projectid=${projectId}&participantid=${participantId}`,
      );
    }
  };

  const fetchProjectRegions = () => {
    axios
      .get(`${FETCH_VIEW_ORIGINATION_PROJECT}/${projectId}`)
      .then((response) => {
        const projectData = response.data;

        const projectRegionsList = projectData.projectRegion
          .slice(1, -1)
          .split(',')
          .map((item) => item.trim());

        setProjectRegions(projectRegionsList);
      })
      .catch(() => {
        /* istanbul ignore else */
        displayToast('error', ERROR_MSGS.FETCH);
      });
  };

  const panToProjectRegion = () => {
    const boundsCoordinates = usRegionsPolygons
      .filter((polygon) => projectRegions.includes(polygon.region))
      .map((polygon) => polygon.polygonCoordinates)
      .reduce((accumulator, current) => [...accumulator, ...current], []);

    const gMapBounds = new window.google.maps.LatLngBounds();
    boundsCoordinates.forEach((bound) => {
      gMapBounds.extend(bound);
    });
    setMapBounds(gMapBounds);
  };

  useEffect(() => {
    fetchEnrollmentCompletionDetails();
    fetchParticipantData(true);
    fetchFarmList();
    fetchProjectRegions();
    fetchCurrentCycleData();
    statusMappingList.length === 0 &&
      fetchAllUserActions().then((data) => setStatusMappingList(data));
  }, []);

  /* istanbul ignore next */
  const performGeoCoding = (options) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode(options, (results, status) => {
      if (status === window.google.maps.GeocoderStatus.OK) {
        const resultBounds = new window.google.maps.LatLngBounds(
          results[0].geometry.viewport.getSouthWest(),
          results[0].geometry.viewport.getNorthEast(),
        );
        setMapBounds(resultBounds);
      } else {
        panToProjectRegion();
      }
    });
  };

  const setMapLocation = (farmAddress) => {
    const { mailingAddress, state, county } = farmAddress;

    if (mailingAddress?.length > 0) {
      performGeoCoding({
        address: `${mailingAddress}, ${state}, ${county}`,
      });
    } else if (state?.length > 0 || county?.length > 0) {
      performGeoCoding({
        componentRestrictions: {
          administrativeArea: state + ' ' + county,
        },
      });
    } else {
      panToProjectRegion();
    }
  };

  const setCurrentFarmLocation = (farmId) => {
    setLoaderCounter((prev) => prev + 1);
    axios
      .get(`${FETCH_GENERAL_FARM_INFO}/${farmId}`, {
        params: {
          internalProjectId: projectId,
          participantOwnerId: participantId,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data) {
          const mailingAddress = data.farmMailingAddress;
          const stateName = data.farmState;
          const county = data.farmCounty;

          setMapLocation({
            mailingAddress: mailingAddress,
            state: stateName,
            county: county,
          });
        }
      })
      .catch(() => {
        /* istanbul ignore else */
        displayToast('error', ERROR_MSGS.FETCH);
      })
      .finally(() => setLoaderCounter((prev) => prev - 1));
  };

  useEffect(() => {
    if (currentFarmId && fetchedFarmValues.length > 0) {
      const foundFarm = fetchedFarmValues.find(
        (farm) => farm.farmId === currentFarmId,
      );
      if (foundFarm) {
        setCurrentFarmInfo(foundFarm);
      }
    }
  }, [currentFarmId, fetchedFarmValues]);

  useEffect(() => {
    setFarmInfo((prevFarmInfo) => [
      ...farmListIds.reduce((acc, farm) => {
        if (!prevFarmInfo.some((info) => info.id === farm.farmId)) {
          acc.push({
            id: farm.farmId,
            status: farm.farmStatus,
            formValidationFlag: farm.formValidationFlag,
            value: farm.farmName,
            isApiPopulated: true,
            fields: getFields(farm),
          });
        }
        return acc;
      }, []),
      ...prevFarmInfo,
    ]);

    const currentFarmFields = farmListIds?.find(
      (farm) => farm.farmId === currentFarmId,
    )?.fieldDetails;
    if (!currentFarmFields) setCurrentFarmFieldsArea(0);
    else {
      const totalFieldArea = currentFarmFields?.reduce(
        (total, field) => total + Number(field.totalFieldArea),
        0,
      );
      setCurrentFarmFieldsArea(totalFieldArea ?? 0);
    }

    setFetchedFarmIds(true);
    extractFarmIdsWithExistingFields(farmListIds);
  }, [farmListIds]);

  useEffect(() => {
    if (fetchedFarmIds && fetchedFarmValues.length === 0) {
      farmListIds.forEach((fetchedFarm) => {
        axios
          .get(`${FETCH_GENERAL_FARM_INFO}/${fetchedFarm.farmId}`, {
            params: {
              internalProjectId: projectId,
              participantOwnerId: participantId,
            },
          })
          .then((response) => {
            const farmIdExists = fetchedFarmValues.some(
              (farm) => farm.farmId === fetchedFarm.farmId,
            );
            if (!farmIdExists) {
              setFetchedFarmValues((prevFetchedFarmValues) => [
                ...prevFetchedFarmValues,
                response.data,
              ]);
            }

            setFarmInfo((prevFarmInfo) => {
              return prevFarmInfo.map((farm) => {
                if (farm.id === fetchedFarm.farmId) {
                  return {
                    ...farm,
                    status:
                      convertStatus(response.data?.formStatus) || farm.status,
                  };
                }
                return farm;
              });
            });
          })
          .catch(() => {
            /* istanbul ignore else */
            displayToast('error', ERROR_MSGS.FETCH);
          });
      });
    }
  }, [farmListIds, fetchedFarmIds, fetchedFarmValues]);

  const handleViewMapClick = (farmId) => {
    if (!farmIdsWithExistingFields.includes(farmId)) {
      setFieldAddTypeModalOpen(true);
    }
  };

  const goToProfile = () => {
    setIsInactiveModalOpen(false);
    setIsInactiveTimerModalOpen(false);
    pause();
    /* istanbul ignore else */
    if (switchState) {
      handleSwitchChange({ target: { checked: false } });
    }
    setRightSectionId(1);
    setCurrentFarmId(-1);
    setExpandedFarmId(-1);
    setFarmInfoCurrentTab(0);
    setShowFieldInfoForm(false);
  };

  const goToFarm = (targetId) => {
    if (+currentFarmId !== +targetId && switchState) {
      handleSwitchChange({ target: { checked: false } });
    }
    setRightSectionId(2);
    setCurrentFarmId(targetId);
    setExpandedFarmId(targetId);
    setFarmInfoCurrentTab(0);
    setShowFieldInfoForm(false);
    setOpenMapView(false);
  };

  const openGoogleMaps = (targetId) => {
    if (rightSectionId === 1) {
      setCurrentFarmId(targetId);
      setExpandedFarmId(targetId);
      setFarmInfoCurrentTab(1);
      setRightSectionId(2);
    } else {
      setCurrentFarmLocation(targetId);
      setCurrentFarmId(targetId);
      setExpandedFarmId(targetId);
      setIsTabSwitched(targetId === currentFarmId);
      setShowFieldInfoForm(false);
      setFarmInfoCurrentTab(1);
      if (!farmIdsWithExistingFields.includes(targetId)) {
        setFieldAddTypeModalOpen(true);
      }
    }
  };

  const getCurrentPage = () => {
    if (rightSectionId === 1) return 'Profile';
    if (rightSectionId == 2 && selectedFieldId !== null && showFieldInfoForm)
      return 'Field';
    if (rightSectionId === 2) return 'Farm';
  };

  const handleAccordianSummaryClick = ({
    targetPlace,
    targetId: farmId,
    targetName: farmName,
  }) => {
    removePolygonsAndInfoWindowsWithError();
    if (targetPlace === LEFT_MENU_SECTION_HEADERS.profileSection) {
      if (userActivityDetected) {
        setUnsavedChangesModalOpenProps({
          isOpen: true,
          nextTab: 0,
          targetFieldId: null,
          targetFieldName: '',
          targetFarmId: null,
          targetFarmName: '',
          navigateNextTo: LEFT_MENU_SECTION_HEADERS.profileSection,
          triggeredFrom: LEFT_MENU_SECTION_HEADERS.profileSection,
        });
      } else {
        goToProfile();
      }
    } else if (targetPlace === LEFT_MENU_SECTION_HEADERS.farmAndFieldsSection) {
      if (userActivityDetected) {
        setUnsavedChangesModalOpenProps({
          isOpen: true,
          nextTab: 0,
          targetFieldId: null,
          targetFieldName: '',
          targetFarmId: farmId,
          targetFarmName: farmName,
          navigateNextTo: LEFT_MENU_SECTION_HEADERS.farmAndFieldsSection,
          triggeredFrom: LEFT_MENU_SECTION_HEADERS.farmAndFieldsSection,
        });
      } else {
        goToFarm(farmId);
      }
    }
  };

  const handleAccordianItemClick = ({
    targetPlace = 'Field',
    targetId: fieldId,
    targetName: fieldName,
    targetParentId: farmId,
  }) => {
    /* istanbul ignore else */
    if (farmId !== currentFarmId && switchState) {
      handleSwitchChange({ target: { checked: false } });
    }
    if (targetPlace === DATA_COLLECTION_CONTENT.ADD_FIELD) {
      if (userActivityDetected) {
        setUnsavedChangesModalOpenProps({
          isOpen: true,
          nextTab: 0,
          targetFieldId: null,
          targetFieldName: '',
          targetFarmId: farmId,
          targetFarmName: '',
          navigateNextTo: targetPlace,
          triggeredFrom: targetPlace,
        });
      } else {
        setUserActivityDetected(false);
        openGoogleMaps(farmId);
        return;
      }
    }
    if (targetPlace === DATA_COLLECTION_CONTENT.addFarms) {
      if (userActivityDetected) {
        setUnsavedChangesModalOpenProps({
          isOpen: true,
          nextTab: 0,
          targetFieldId: null,
          targetFieldName: '',
          targetFarmId: farmId,
          targetFarmName: '',
          navigateNextTo: targetPlace,
          triggeredFrom: targetPlace,
        });
      } else {
        addFarmClickHandler();
      }
    }

    if (userActivityDetected) {
      setUnsavedChangesModalOpenProps({
        isOpen: true,
        nextTab: 0,
        targetFieldId: fieldId,
        targetFieldName: fieldName,
        targetFarmId: farmId,
        targetFarmName: '',
        navigateNextTo: targetPlace,
        triggeredFrom: targetPlace,
      });
    } else {
      openFieldInformation({ id: fieldId, name: fieldName, farmId: farmId });
    }
  };
  const shouldNavigateToLandingPage = async () => {
    if (!isParticipantContributor) return Promise.resolve(false);
    return isActivitySubmitted({
      participantId,
      projectId,
      activityType: ACTIVITY_TYPES.participant,
      statusMappingList,
    });
  };

  const NavigateOutOfEnrollment = () => {
    const navigatePath = checkTernaryCondition(
      isParticipantContributor || isParticipant,
      pathName.participant.landingPage,
      `${pathName.originationParticipants.view}/${participantId}?projectid=${projectId}&tab=Activity`,
    );
    navigate(navigatePath);
  };
  const value = React.useMemo(
    () => ({
      sectionList,
      setSectionList,
      rightSectionId,
      setRightSectionId,
      participantInfo,
      setParticipantInfo,
      farmInfo,
      setFarmInfo,
      expandedFarmId,
      setExpandedFarmId,
      openMapView,
      setOpenMapView,
      updateFarmFieldName,
      selectedFieldId,
      setSelectedFieldId,
      farmListIds,
      setFarmListIds,
      currentFarmId,
      setCurrentFarmId,
      farmLoading,
      setFarmLoading,
      currentFarmInfo,
      setCurrentFarmInfo,
      fetchedFarmValues,
      setFetchedFarmValues,
      state,
      saveAndCloseClicked,
      setSaveAndCloseClicked,
      setIsEditModeOn,
      isEditModeOn,
      savedFieldsForms: savedFieldForms,
      setSavedFiledForms: setSavedFieldForms,
      showFieldInfoForm,
      setShowFieldInfoForm,
      fieldAddTypeModalOpen,
      setFieldAddTypeModalOpen,
      selectedFieldAddType,
      setSelectedFieldAddType,
      farmInfoCurrentTab,
      setFarmInfoCurrentTab,
      farmsIdsWithExistingFields: farmIdsWithExistingFields,
      fieldPolygons,
      setFieldPolygons,
      addFieldsFromShapeFile,
      addFarmClicked,
      setAddFarmClicked,
      setShapeFileUploadModalOpen,
      fieldIdsAddedFromMap,
      setFieldIdsAddedFromMap,
      isTabSwitched,
      setIsTabSwitched,
      setMapBounds,
      setCurrentFarmLocation,
      fetchCountyList,
      fetchStateList,
      isFormUnmounted,
      setIsFormUnmounted,
      submitData,
      setSubmitData,
      userActivityDetected,
      setUserActivityDetected,
      isFieldDeleted,
      setIsFieldDeleted,
      modalData,
      setModalData,
      participantProfileStatus,
      setParticipantProfileStatus,
      isProfileDataEditable,
      setIsProfileDataEditable,
      enrolledFarmIds,
      enrolledFieldIds,
      hasNotApprovedFarmOrFields,
      isFarmCsafUpdatedBySystem,
      setIsFarmCsafUpdatedBySystem,
      isFieldSubmitted,
      setIsFieldSubmitted,
      setHasNotApprovedFarmOrFields,
      fetchParticipantData,
      loading,
      setLoading,
      isParticipantContributor,
      handleViewMapClick,
      statusList,
      setStatusList,
      farmListStatus,
      setFarmListStatus,
      currentCycleDataYear,
      setCurrentCycleDataYear,
      setCurrentCycleStartDate,
      setCurrentCycleEndDate,
      currentCycleStartDate,
      currentCycleEndDate,
      triggerFieldInfoFetch,
      setTriggerFieldInfoFetch,
      unsavedChangesModalOpenProps,
      setUnsavedChangesModalOpenProps,
      closeUnsavedChangesModal,
      triggerFarmInfoSubmit,
      setTriggerFarmInfoSubmit,
      triggerFieldInfoSubmit,
      setTriggerFieldInfoSubmit,
      setFieldInfoTab,
      addFarmData,
      getUpdatedFarmDetails,
      addFarmClickHandler,
      addedFromUiFarmIds,
      setAddedFromUiFarmIds,
      handleLeaveWithoutSave,
      handleSaveAndLeave,
      handleSubmitCallback,
      triggerProfileInfoSubmit,
      setTriggerProfileInfoSubmit,
      isPolygonOverlapping,
      setTempBoundaries,
      addFieldToFarm,
      handleFieldBoundaryClick,
      getCurrentFarmFieldsArea,
      statusMappingList,
      setStatusMappingList,
      shouldNavigateToLandingPage,
      goToProfile,
      switchState,
      activeUser,
      setActiveUser,
      isSwitchDisabled,
      disableSubmitAllFarmsBtn,
      setDisableSubmitAllFarmsBtn,
      isEnrollmentCompletedAllTogether,
      setIsEnrollmentCompletedAllTogether,
      fetchEnrollmentCompletionDetails,
      enrollmentDataCollectionCompleted,
      goToFarm,
      hasUnApprovedFarmsOrFields,
      loaderCounter,
      setLoaderCounter,
      NavigateOutOfEnrollment,
    }),
    [
      rightSectionId,
      sectionList,
      participantInfo,
      farmInfo,
      expandedFarmId,
      selectedFieldId,
      farmListIds,
      currentFarmId,
      farmLoading,
      currentFarmInfo,
      fetchedFarmValues,
      state,
      isEditModeOn,
      savedFieldForms,
      saveAndCloseClicked,
      showFieldInfoForm,
      fieldAddTypeModalOpen,
      selectedFieldAddType,
      farmInfoCurrentTab,
      farmIdsWithExistingFields,
      fieldPolygons,
      addFarmClicked,
      fieldIdsAddedFromMap,
      isTabSwitched,
      isFormUnmounted,
      submitData,
      userActivityDetected,
      isFieldDeleted,
      modalData,
      participantProfileStatus,
      isProfileDataEditable,
      enrolledFarmIds,
      enrolledFieldIds,
      hasNotApprovedFarmOrFields,
      isFarmCsafUpdatedBySystem,
      isFieldSubmitted,
      loading,
      isParticipantContributor,
      statusList,
      setStatusList,
      farmListStatus,
      setFarmListStatus,
      currentCycleDataYear,
      currentCycleStartDate,
      currentCycleEndDate,
      triggerFieldInfoFetch,
      unsavedChangesModalOpenProps,
      triggerFarmInfoSubmit,
      triggerFieldInfoSubmit,
      fieldInfoTab,
      addedFromUiFarmIds,
      triggerProfileInfoSubmit,
      statusMappingList,
      switchState,
      activeUser,
      isSwitchDisabled,
      disableSubmitAllFarmsBtn,
      isEnrollmentCompletedAllTogether,
      enrollmentDataCollectionCompleted,
      hasUnApprovedFarmsOrFields,
      loaderCounter,
    ],
  );

  return (
    <participantInfoContext.Provider value={value}>
      {children}
    </participantInfoContext.Provider>
  );
};

ParticipantInfoProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
